<template>
  <MainLayout>
    <template v-slot:mainView>
      <router-view />
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "./views/layouts/MainLayout.vue";
import gql from "graphql-tag";
import { useToast } from 'vue-toastification';

export default {
  name: "App",
  components: {
    MainLayout,
  },

  setup() {
      const toast = useToast()

    return {
      toast
    }
  },

  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.u) {
      this.$store.commit("SET_TOKEN", params.u);
      this.getCurrentUserInfo();
      this.$router.push('/');
    }

    if(this.$store.getters.getToken) {
      this.getCurrentUserInfo();
    }
  },

  methods: {
    getCurrentUserInfo() {
      this.$apollo
        .query({
          query: gql`
            query {
              me {
                id
                name
                phone

                unreadNotificationsCount

                subscription_model {
                  id
                  operator
                  active_billing_until
                  has_active_billing
                }
              }
            }
          `,
        })
        .then((data) => {
          // Result
          this.$store.commit("SET_USER", data.data.me);
          this.$store.commit('UPDATE_UNREAD_NOTIFICATIONS_COUNT', data.data.me?.unreadNotificationsCount)
        })
        .catch((error) => {
          this.reportError(error)
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;

      if (graphQLErrors[0].extensions.category === "authentication") {
        this.$router.push({ name: 'Profile' })
      }
    }
  },
};
</script>

<style lang="scss">
///
</style>
