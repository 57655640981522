import { AUTH_TOKEN_KEY } from '../constants'

export default function auth({ next, router }) {
    let demoModeActive = process.env.VUE_APP_DEMO_MODE_ACTIVE == 'true';
    if (demoModeActive) {
        return next();
    }
    
    if (!localStorage.getItem(AUTH_TOKEN_KEY) || localStorage.getItem(AUTH_TOKEN_KEY) == 'null') {
        return next({ name: 'LoginInfo' })
    } else {
        return next();
    }
}