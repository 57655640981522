<template>
  <div @click="openTrack" class="item vertical-card pb-3 me-3 cursor-pointer shadow-1-strong h-100 card-hover">
    <div class="image">
      <img :src="track.thumbnail_url" alt="" class="author-image" loading="lazy" />
    </div>
    <div class="labels col px-3 rounded">
      <div class="font-weight-bold mt-3 card-title">{{ track.name_ar }}</div>
      <div class="text-sm">
        <div class="text-sm d-inline-block" v-for="author,index in track.authors" :key="author.id">
            {{ author.name_ar }}
            <span v-if="index != Object.keys(track.authors).length - 1" class="mx-2 font-weight-bold">· </span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['track', 'tracklist'],

  data() {
    return {
      demoModeActive: process.env.VUE_APP_DEMO_MODE_ACTIVE == 'true',
    };
  },

  computed: {
    selectedTrackIndex () {
      let index = 0;
      this.tracklist.forEach((t, i) => {
        if (t.id == this.track.id) {
          index = i;
        }
      });
      return index;
    }
  },

  methods: {
    openTrack(showPlayer = true) {
      if(this.$store.getters.isLoggedIn || this.demoModeActive) {
        this.$store.commit('SET_TRACKLIST', { 
          tracklist: this.tracklist, 
          index: this.selectedTrackIndex,
          track: this.track,
          })
        if(showPlayer) {
          this.$router.push({ name: 'Track', params: { id: this.track.id } })
        }
      } else {
        this.$router.push({ name: 'LoginInfo' })
      }
      return;
    }
  }
};
</script>

<style></style>
